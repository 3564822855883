import useAccountStore from "../stores/account/account.store";

// Fetcher function for SWR
export const accountFetcher = async (url) => {
	
	const response = await fetch(url);
	
	const result = await response.json();
	if(response.status === 401){
		return response.status;
	}
	return result[0];
};
