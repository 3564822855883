const swrOptions = {
	revalidateOnFocus: false,
	revalidateOnReconnect: false,
	dedupingInterval: 60000,
	onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
		// Never retry on 404.
		if (error.status === 404) return alert("401 Pak Not Access");
	},
};

export default swrOptions;
